import { Link, NavLink, useLocation } from "react-router-dom";
import "../css/footer.css";
import { data } from "../data/bec";
import { useSelector } from "react-redux";
import { todosSelector } from "../stor/selectors";
import { useState } from "react";
export function Footer(params) {
  const [first, setfirst] = useState(1);
  const data = useSelector(todosSelector);
  let location =useLocation()
  const parts =[]= location.pathname.split("/");
  
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_left">
          <Link className="footer_logo" to="/">
            <img
              src={process.env.PUBLIC_URL + "/logo_all.png"}
              alt="Спецавто Логотип"
            />
          </Link>
          {/* <p className="header_slogan">
            <span>ПРОИЗВОДСТВО</span> <br /> СПЕЦАВТОМОБИЛЕЙ и ПРИЦЕПОВ
          </p> */}
        </div>
        <div className="footer_right">
          <div className="footer_all_details">
            <details name="footer-product" className="footer-product" open>
              <summary>
                  <p className="footer_text_item">Топливозаправщик</p>
                  <img className="footer_text_item_img" src={process.env.PUBLIC_URL + "/footer_left.png"} alt="стрелка"/>
              </summary>
              <div className="footer_text_item_mini">
                  <Link to="/katalog/uaz/toplivozapravschik_1_sekcionnyy_36223_(uaz)">
                      <p>Топливозаправщик односекционный УАЗ - 36223</p>
                  </Link>
                  <Link to="/katalog/uaz/toplivozapravschik_1_sekcionnyy_362233_(uaz)">
                      <p>Топливозаправщик односекционный УАЗ - 362233</p>
                  </Link>
                  <Link to="/katalog/uaz/toplivozapravschik_dvuhsekcionnyy_36223_(uaz)">
                      <p>Топливозаправщик двухсекционный УАЗ - 36223</p>
                  </Link>
                  <Link to="/katalog/uaz/toplivozapravschik_dvuhsekcionnyy_362233">
                      <p>Топливозаправщик двухсекционный УАЗ - 362233</p>
                  </Link>
              </div>
            </details>
            <details name="footer-product" className="footer-product">
              <summary>
                  <p className="footer_text_item">Пищевая цистерна</p>
                  <img className="footer_text_item_img" src={process.env.PUBLIC_URL + "/footer_left.png"} alt="стрелка"/>
              </summary>
              <div className="footer_text_item_mini">
                  <Link to="/katalog/uaz/molokovoz_362213_(uaz)_">
                      <p>Молоковоз 362213 (УАЗ)</p>
                  </Link>
                  <Link to="/katalog/uaz/molokovoz_36221_(uaz)_">
                      <p>Молоковоз 36221 (УАЗ)</p>
                  </Link>
              </div>
            </details>
            <details name="footer-product" className="footer-product">
              <summary>
                  <p className="footer_text_item">Пожарный автомобиль</p>
                  <img className="footer_text_item_img" src={process.env.PUBLIC_URL + "/footer_left.png"} alt="стрелка"/>
              </summary>
              <div className="footer_text_item_mini">
                  <Link to="/katalog/uaz/pozharnyy_362223_(uaz)">
                      <p>Пожарный 362223 (УАЗ)</p>
                  </Link>
                  <Link to="/katalog/uaz/pozharnyy_36222_(uaz)">
                      <p>Пожарный 36222 (УАЗ)</p>
                  </Link>
              </div>
            </details>
            <details name="footer-product" className="footer-product">
              <summary>
                  <p className="footer_text_item">Прицеп</p>
                  <img className="footer_text_item_img" src={process.env.PUBLIC_URL + "/footer_left.png"} alt="стрелка"/>
              </summary>
              <div className="footer_text_item_mini">
                  <Link to="/katalog/uaz/rybovoz_36221_(uaz)">
                      <p>Рыбовоз 36221 (УАЗ)</p>
                  </Link>
              </div>
            </details>
            <details name="footer-product" className="footer-product">
              <summary>
                  <p className="footer_text_item">Фургон</p>
                  <img className="footer_text_item_img" src={process.env.PUBLIC_URL + "/footer_left.png"} alt="стрелка"/>
              </summary>
              <div className="footer_text_item_mini">
                  <Link to="/katalog/uaz/hlebovoz_290513_(uaz)">
                      <p>Хлебовоз 290513 (УАЗ)</p>
                  </Link>
                  <Link to="/katalog/uaz/hlebovoz_29051_(uaz)">
                      <p>Хлебовоз 29051 (УАЗ)</p>
                  </Link>
                  <Link to="/katalog/uaz/avtolavka_290513_(uaz)">
                      <p>Автолавка 290513 (УАЗ)</p>
                  </Link>
                  <Link to="/katalog/uaz/prodovolstvennyy_furgon_290513_(uaz)">
                      <p>Продовольственный фургон 290513 (УАЗ)</p>
                  </Link>
              </div>
            </details>
          </div>
          <div className="mapSite_container">
            <div className="mapSite footer_link">
              <Link className="footer_main_link" to="/news">Новости</Link>
              <Link className="footer_main_link" to="/kompani">О компании</Link>
            </div>
            <div className="mapSite">
              <Link className="footer_main_link footer_main_link_contact" to="/kontact">Контакты</Link>
              <div className="ad">
                <span className="footer_span">Адрес:</span>
                <a href="/kontact">
                  432045, Россия, г. Ульяновск, Московское шоссе, д. 3, офис  224
                </a>
              </div>
              <div className="ad">
                <span className="footer_span">Телефон:</span>
                <a href="tel:+79603721993">+7 (960) 372-19-93</a>
              </div>
              <div className="ad">
                <span></span>
                <a href="tel:+79063902544">+7 (906) 390-25-44</a>
              </div>
              <div className="ad">
                <span className="footer_span">Почта:</span>
                <a href="mailto:mail@specavto.com">mail@specavto.com</a>
              </div>
            </div>
          </div>
        </div>

        {/* <nav className="footer-menu">
          <Link className="footer-menu_link" to="/">
            О КОМПАНИИ
          </Link>
          <Link className="footer-menu_link" to="/">
            НОВОСТИ
          </Link>
          <Link className="footer-menu_link" to="/kontact">
            Контакты
          </Link>
          <div className="footer-menu_contact_box">
            <a className="footer-menu_contact" href="https://vk.com/specavto73">
              <img
                src={process.env.PUBLIC_URL + "/img/vk.svg"}
                alt="Наша группа В Контакте"
              />
            </a>
            <a className="footer-menu_contact" href="/">
              <img
                src={process.env.PUBLIC_URL + "/img/tg.svg"}
                alt="Наш канал в Telegramm"
              />
            </a>
            <a href="/" className="footer-menu_contact">
              <img
                src={process.env.PUBLIC_URL + "/img/watsap.svg"}
                alt="Связаться с нами в WatsApp"
              />
            </a>
            <button onClick={()=>params.setfos(!params.fos)} className="header-right__button">
              <img src={process.env.PUBLIC_URL + "/img/mail.svg"} alt="mail" />
              <span>НАПИСАТЬ НАМ</span>
            </button>
          </div>
        </nav>
        <div className="footer-list">
            <div className="footer-list_item">
                <h4>
                УАЗ
                </h4>
                <div className="footer-list_items">
                    {data.all&&
                    data.all.map((e,i)=>(
                      <NavLink to={`/katalog/uaz/${e.translit}`}  key={i} className="footer-list_items_link">
                        {e.class + " "}<span className="orange">{e.cat} - </span> {e.model}
                      </NavLink>
                  ))}
                </div>
            </div>
        </div> */}
      </div>
    </footer>
  );
}
